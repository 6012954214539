const environments = {
  localhost: {
    analyticsUrl: 'https://events.staging.asics.digital/events/record',
    // Local backend: 'https://users-api.local.dev.asics.digital:8443'
    apiBasePath: 'https://oneasics.dev1.asics.com',
    asicsIDBaseUrl: 'https://oneasics.dev1.asics.com',
    clientId: 'oneasics_instore_local',
    oneTrustDataDomainScript: 'aa76ace3-3c78-4f9a-8e1a-559c1cc9c158-test',
    name: 'localhost',
    redirectURI: `https://localhost:3000${process.env.PUBLIC_URL}/login`,
    teamworkPollingDelayMilliseconds: 5000,
    teamworkPollingMaxTries: 10,
  },
  'oneasics-instore-dev1.dev.asics.digital': {
    analyticsUrl: 'https://events.staging.asics.digital/events/record',
    apiBasePath: 'https://oneasics.dev1.asics.com',
    asicsIDBaseUrl: 'https://oneasics.dev1.asics.com',
    oneTrustDataDomainScript: 'aa76ace3-3c78-4f9a-8e1a-559c1cc9c158-test',
    name: 'dev1',
    redirectURI: `https://oneasics-instore-dev1.dev.asics.digital${process.env.PUBLIC_URL}/login`,
    teamworkPollingDelayMilliseconds: 5000,
    teamworkPollingMaxTries: 10,
  },
  'oneasics-instore-dev2.dev.asics.digital': {
    analyticsUrl: 'https://events.staging.asics.digital/events/record',
    apiBasePath: 'https://oneasics.dev2.asics.com',
    asicsIDBaseUrl: 'https://oneasics.dev2.asics.com',
    oneTrustDataDomainScript: 'aa76ace3-3c78-4f9a-8e1a-559c1cc9c158-test',
    name: 'dev2',
    redirectURI: `https://oneasics-instore-dev2.dev.asics.digital${process.env.PUBLIC_URL}/login`,
    teamworkPollingDelayMilliseconds: 5000,
    teamworkPollingMaxTries: 10,
  },
  'oneasics-instore.staging.asics.digital': {
    analyticsUrl: 'https://events.staging.asics.digital/events/record',
    apiBasePath: 'https://id-sandbox.asics.com',
    asicsIDBaseUrl: 'https://id-sandbox.asics.com',
    oneTrustDataDomainScript: 'aa76ace3-3c78-4f9a-8e1a-559c1cc9c158-test',
    name: 'staging',
    redirectURI: `https://oneasics-instore.staging.asics.digital${process.env.PUBLIC_URL}/login`,
    teamworkPollingDelayMilliseconds: 5000,
    teamworkPollingMaxTries: 10,
  },
  'dev.one.asics.com': {
    analyticsUrl: 'https://events.staging.asics.digital/events/record',
    apiBasePath: 'https://oneasics.dev1.asics.com',
    asicsIDBaseUrl: 'https://oneasics.dev1.asics.com',
    oneTrustDataDomainScript: '94172d86-7c8d-47ba-9e4b-da63d163d789',
    name: 'dev1',
    redirectURI: `https://dev.one.asics.com${process.env.PUBLIC_URL}/login`,
    teamworkPollingDelayMilliseconds: 5000,
    teamworkPollingMaxTries: 10,
  },
  'staging.one.asics.com': {
    analyticsUrl: 'https://events.staging.asics.digital/events/record',
    apiBasePath: 'https://id-sandbox.asics.com',
    asicsIDBaseUrl: 'https://id-sandbox.asics.com',
    oneTrustDataDomainScript: '54ec7074-71d4-4d12-a4e0-e72f26b166fd',
    name: 'staging',
    redirectURI: `https://staging.one.asics.com${process.env.PUBLIC_URL}/login`,
    teamworkPollingDelayMilliseconds: 5000,
    teamworkPollingMaxTries: 10,
  },
  'one.asics.com': {
    analyticsUrl: 'https://events.asics.digital/events/record',
    apiBasePath: 'https://id.asics.com',
    asicsIDBaseUrl: 'https://id.asics.com',
    oneTrustDataDomainScript: '94172d86-7c8d-47ba-9e4b-da63d163d789',
    name: 'prod',
    redirectURI: `https://one.asics.com${process.env.PUBLIC_URL}/login`,
    teamworkPollingDelayMilliseconds: 2000,
    teamworkPollingMaxTries: 5,
  },
};

export default environments;
